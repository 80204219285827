import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";
import javascriptIcon from "../assets/img/javascript-original.svg";
import htmlIcon from "../assets/img/html5-original.svg";
import cssIcon from "../assets/img/css3-original.svg";
import angularIcon from "../assets/img/angularjs-original.svg";
import bootstrapIcon from "../assets/img/bootstrap-original.svg";
import csharpIcon from "../assets/img/csharp-original.svg";
import dotnetcoreIcon from "../assets/img/dotnetcore-original.svg";
import jqueryIcon from "../assets/img/jquery-original-wordmark.svg";
import mssqlIcon from "../assets/img/microsoftsqlserver-plain.svg";
import mysqlIcon from "../assets/img/mysql-original-wordmark.svg";
import nodejsIcon from "../assets/img/nodejs-original.svg";
import expressIcon from "../assets/img/express-original.svg";
import reactIcon from "../assets/img/react-original.svg"

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <section className="skill" id="skills">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="skill-bx zoomIn">
                            <h2>Skills</h2>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                            <Carousel swipeable={true} autoPlay={true} autoPlaySpeed={5000} draggable={true} responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                                <div className="item">
                                    <img src={javascriptIcon} alt="JavaScript Icon" />
                                    <h5>JavaScript</h5>
                                </div>
                                <div className="item">
                                    <img src={htmlIcon} alt="HTML5 Icon" />
                                    <h5>HTML5</h5>
                                </div>
                                <div className="item">
                                    <img src={cssIcon} alt="CSS3 Icon" />
                                    <h5>CSS3</h5>
                                </div>
                                <div className="item">
                                    <img src={reactIcon} alt="React Icon" />
                                    <h5>React</h5>
                                </div>
                                <div className="item">
                                    <img src={nodejsIcon} alt="NodeJS Icon" />
                                    <h5>NodeJS</h5>
                                </div>
                                <div className="item">
                                    <img src={expressIcon} alt="Express Icon" />
                                    <h5>Express</h5>
                                </div>
                                <div className="item">
                                    <img src={mssqlIcon} alt="Microsoft SQL Server Icon" />
                                    <h5>Microsoft SQL Server</h5>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            {/* <img className="background-image-left" alt="background img left" src={colorSharp} /> */}
        </section>
    );

}