import { useState } from "react";
import React, { useRef } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import emailjs from 'emailjs-com';

export const Contact = () => {
    // const formInitialDetails = {
    //     firstName: '',
    //     lastName: '',
    //     email: '',
    //     phone: '',
    //     message: ''
    // }
    // const [formDetails, setFormDetails] = useState(formInitialDetails);
    // const [buttonText, setButtonText] = useState('Send');
    // const [status, setStatus] = useState({});

    // const onFormUpdate = (category, value) => {
    //     setFormDetails({
    //         ...formDetails,
    //         [category]: value
    //     })
    // }

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setButtonText("Sending...");
    //     let response = await fetch("http://localhost:5000/contact", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json;charset=utf-8",
    //         },
    //         body: JSON.stringify(formDetails),
    //     });
    //     setButtonText("Send");
    //     let result = await response.json();
    //     setFormDetails(formInitialDetails);
    //     if (result.code === 200) {
    //         setStatus({ succes: true, message: 'Message sent successfully' });
    //     } else {
    //         setStatus({ succes: false, message: 'Something went wrong, please try again later.' });
    //     }
    // };

    const form = useRef();

    const [buttonText, setButtonText] = useState('Send');
    const [status, setStatus] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = async (e) => {
        e.preventDefault();
        setButtonText("Sending...");
        emailjs.sendForm('service_finsp5g', 'template_st7qdn1', form.current, '9rnBmbMW3I6MShdXp')
            .then((result) => {
                console.log(result);
                setStatus({ succes: true, message: 'Message sent successfully' });
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setMessage('');
            }, (error) => {
                console.log(error);
                setStatus({ succes: false, message: 'Something went wrong, please try again later.' });
            });
        setButtonText("Send");
    };

    return (
        <section className="contact" id="connect">
            <Container>
                <Row className="align-items-center">
                    <Col size={12} md={6}>
                        <TrackVisibility>
                            {({ isVisible }) =>
                                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us" />
                            }
                        </TrackVisibility>
                    </Col>
                    <Col size={12} md={6}>
                        <TrackVisibility>
                            {({ isVisible }) =>
                                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                                    <h2>Get In Touch</h2>
                                    <form ref={form} onSubmit={sendEmail}>
                                        <Row>
                                            <Col size={12} sm={6} className="px-1">
                                                <input id="firstname" type="text" name="user_firstname" placeholder="First Name" onChange={event => setFirstName(event.target.value)} value={firstName} />
                                            </Col>
                                            <Col size={12} sm={6} className="px-1">
                                                <input id="lastname" type="text" name="user_lastname" placeholder="Last Name" onChange={event => setLastName(event.target.value)} value={lastName} />
                                            </Col>
                                            <Col size={12} sm={6} className="px-1">
                                                <input id="email" type="email" name="user_email" placeholder="Email Address" onChange={event => setEmail(event.target.value)} value={email} />
                                            </Col>
                                            <Col size={12} sm={6} className="px-1">
                                                <input id="phone" type="tel" name="user_phone" placeholder="Phone No." onChange={event => setPhone(event.target.value)} value={phone} />
                                            </Col>
                                            <Col size={12} className="px-1">
                                                <textarea id="message" rows="6" name="message" placeholder="Message" onChange={event => setMessage(event.target.value)} value={message}></textarea>
                                                <button type="submit" value="Send"><span>{buttonText}</span></button>
                                            </Col>
                                            {
                                                status.message &&
                                                <Col>
                                                    <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                                                </Col>
                                            }
                                        </Row>
                                    </form>
                                </div>}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}