import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, projLink }) => {
    return (
        <Col size={12} sm={6} md={4}>
            <div className="proj-imgbx">
                <img src={imgUrl} alt="project card" />
                <div className="proj-txtx">
                    <a target="_blank" rel="noreferrer" href={projLink}><h4>{title}</h4></a>
                    <span>{description}</span>
                </div>
            </div>
        </Col >
    )
}